'use client';

import React, {
  ErrorInfo,
  PropsWithChildren,
  PureComponent,
  type ComponentType,
  type ReactNode
} from 'react';
import type { CommonErrorBoundary } from './CommonErrorBoundary';

/** Error boundary state. */
export type ErrorBoundaryState =
  | { didCatch: true; error: unknown }
  | { didCatch: false; error: null };

/**
 * A function which returns a React component to be
 * displayed as a fallback when an error occurs.
 * @param error - The error that was caught.
 * @param clearError - A function to clear the error state.
 * @returns React component to be displayed as a fallback.
 */
export type ErrorBoundaryFallbackFn = (
  error: unknown,
  clearError: () => void
) => React.ReactNode;

/** A prop which determines what to render when an error occurs. */
export type ErrorBoundaryFallback = ErrorBoundaryFallbackFn | ReactNode;

/** Props for a basic error boundary using a fallback component. */
export interface IErrorBoundaryFallbackComponentProps {
  /** The error that was caught by the error boundary. */
  error: unknown;
  /** A function to clear the error state and reset the boundary. */
  reset: () => void;
}

interface IErrorBoundaryBaseProps extends PropsWithChildren {
  /**
   * Either a {@link ReactNode} or a function that returns a `ReactNode`
   * to be displayed when an error occurs.
   */
  fallback?: ErrorBoundaryFallback;

  /** A React component to be displayed when an error occurs. */
  fallbackComponent?: ComponentType<IErrorBoundaryFallbackComponentProps>;

  /**
   * A function to be called when an error occurs.
   * @param error - The error that was caught.
   * @param errorInfo - The error info object.
   */
  onError?: (error: unknown, errorInfo: ErrorInfo) => void;

  /**
   * A function to be called when the error is _explicitly_ reset.
   * It will **NOT** be called if:
   * - A new render error replaces the previous one.
   * - The component is reset via a {@link https://react.dev/learn/rendering-lists#why-does-react-need-keys React key change}.
   *
   * The function is invoked before the component state is updated.
   *
   * @param error - The error that was caught.
   */
  onReset?: (error: unknown) => void;
}

/** Props for an error boundary using the {@link fallback} prop. */
interface IErrorBoundaryPropsWithFallback extends IErrorBoundaryBaseProps {
  // eslint-disable-next-line jsdoc/require-jsdoc -- prevent JSDoc from being inherited.
  fallback?: ErrorBoundaryFallback;
  /** @inheritdoc */
  fallbackComponent?: never;
}

/** Props for the error boundary fallback component. */
interface IErrorBoundaryPropsWithFallbackComponent
  extends IErrorBoundaryBaseProps {
  /** @inheritdoc */
  fallback?: never;
  /** @inheritdoc */
  fallbackComponent: ComponentType<IErrorBoundaryFallbackComponentProps>;
}

/** Props for a generic error boundary. */
export type ErrorBoundaryProps =
  | IErrorBoundaryPropsWithFallback
  | IErrorBoundaryPropsWithFallbackComponent;

/** Props for the {@link BasicErrorBoundary} component. */
export type BasicErrorBoundaryProps = ErrorBoundaryProps & {
  /**
   * Either a {@link ReactNode} or a function that returns a `ReactNode`
   * to be displayed when an error occurs.
   *
   * **NOTE**: If no fallback is provided, then the error boundary
   * will render nothing when an error occurs.
   */
  fallback?: IErrorBoundaryPropsWithFallback['fallback'];
};

/**
 * A low-level error boundary component for catching render errors and displaying a fallback UI.
 *
 * Use this if you need more control over the error boundary behavior. Otherwise, use one of the higher-level error boundaries, like {@link CommonErrorBoundary}.
 */
export class BasicErrorBoundary extends PureComponent<
  BasicErrorBoundaryProps,
  ErrorBoundaryState
> {
  /** @inheritdoc */
  public constructor(props: BasicErrorBoundaryProps) {
    super(props);

    // Set the initial state
    this.state = { didCatch: false, error: null };
  }

  /**
   * Derives and returns the new state from the caught render error.
   * This method is called during React's "render" phase, meaning it
   * must be not contain side effects.
   *
   * @param error - The caught render error.
   * @returns The new state to be set.
   *
   * **Advanced Note**:
   *
   * In React 18, when a component errors, React still "prerenders" its
   * sibling components as it unwinds the stack and propagates the error.
   * This might not match developer intuition since it differs from try/catch.
   * However in React 19, {@link https://github.com/facebook/react/pull/26380 this may be changed} pending {@link https://github.com/facebook/react/issues/29898 further discussion}.
   */
  public static getDerivedStateFromError(error: unknown): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return { didCatch: true, error };
  }

  /**
   *
   * **Advanced Note**:
   *
   * React calls this method for each error caught by the error boundary. However,
   * since it is called during the "commit" phase, the state updates from
   * `getDerivedStateFromError` have been batched and applied. In other words,
   * we cannot assume that the component state at the time this method is called
   * exactly matches the state derived from the error.
   *
   * @inheritdoc
   */
  public override componentDidCatch(
    error: unknown,
    errorInfo: ErrorInfo
  ): void {
    const { onError } = this.props;
    onError?.(error, errorInfo);
  }

  /**
   * Resets the component to its initial state, and tries to re-render the children.
   */
  private reset = (): void => {
    const { didCatch, error } = this.state;
    const { onReset } = this.props;

    if (didCatch) {
      onReset?.(error);
      this.setState({ didCatch: false, error: null });
    }
  };

  /** @inheritdoc */
  public override render(): React.ReactNode {
    const { didCatch, error } = this.state;
    const {
      children,
      fallback,
      fallbackComponent: FallbackComponent
    } = this.props;

    /**
     * **Advanced Note**: If the fallback throws an error during its initial render,
     * then React will traverse up the component tree to find the next
     * ancestor error boundary.
     * @see {@link https://jser.dev/2023-05-26-how-does-errorboundary-work/ How does ErrorBoundary work internally in React?}
     */

    if (didCatch) {
      if (FallbackComponent) {
        return <FallbackComponent error={error} reset={this.reset} />;
      }

      if (typeof fallback === 'function') {
        return fallback(error, this.reset);
      }

      return fallback;
    }

    return children;
  }
}
