'use client';

import { useEffect, useState, type FC, type PropsWithChildren } from 'react';
import { getBreakpointSize } from './internal';
import { BreakpointSizeContext } from './internal/BreakpointSizeContext';

/** A component that provides the current breakpoint size to its children. */
export const BreakpointSizeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [currentSize, setCurrentSize] = useState(() =>
    /**
     * Generally, it is very dangerous to initialize state differently between
     * the server and the client; doing so will likely cause hydration errors.
     * However, this state is only meant to be used by the `BreakpointGroup`
     * component, which is carefully designed to avoid hydration errors, while
     * providing the desired rendering behavior.
     */
    typeof window === 'undefined' ? null : getBreakpointSize(window.innerWidth)
  );

  useEffect(function subscribeToWindowResize() {
    const onResize = (): void => {
      /**
       * Note: we don't need to debounce here because:
       *  - `getBreakpointSize` is a cheap operation and React will bailout
       *  of the state update if the breakpoint size doesn't change.
       *  - Any delay in updating the breakpoint will cause a temporary flash
       *  of missing content. This is because the CSS classes hide the old UI faster
       *  than React can update to the new UI. This results in an unpleasant user experience.
       */
      setCurrentSize(getBreakpointSize(window.innerWidth));
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <BreakpointSizeContext.Provider value={currentSize}>
      {children}
    </BreakpointSizeContext.Provider>
  );
};
