import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import LoggerService from '@/services/isomorphic/LoggerService';
import { difference } from '@/utils/array-utils';
import { exhaustiveGuard } from '@/utils/function-utils';
import { isNullish } from '@/utils/null-utils';
import type { ComponentProps, ReactElement, ReactNode } from 'react';
import type { NonEmptyArray } from '@/type-utils';
import {
  breakpointDeviceToSize,
  breakpointSizeRanges,
  breakpointSizes,
  breakpointSizeToIndexMap,
  type BreakpointDevice,
  type BreakpointMediaKey,
  type BreakpointSize
} from '.';
import type { Breakpoint, BreakpointSizeRange } from '..';
import { BreakpointGroupError } from '../BreakpointGroupError';

import S from './styles.module.scss';

/** A map from breakpoint sizes to their corresponding CSS classNames. */
const breakpointSizeToClassMap = {
  xxs: S.xxs,
  xs: S.xs,
  sm: S.sm,
  md: S.md,
  lg: S.lg,
  xl: S.xl
} as const satisfies Record<BreakpointSize, string>;

/** Represents a configuration for a breakpoint. */
export interface IBreakpointConfig {
  /** An identifier for the configuration. */
  readonly id: number;
  /** A set of breakpoint sizes that match the configuration. */
  readonly matches: Set<BreakpointSize>;
  /** The CSS `className` to apply to the wrapper element. */
  readonly className: string;
}

/** Represents a mapping between a breakpoint config and its corresponding React node. */
export type BreakpointConfigWithNode = readonly [IBreakpointConfig, ReactNode];

/**
 * There is a finite number of possible breakpoint configurations, so we
 * cache them to reduce memory usage, and to reduce the RSC payload size.
 *
 * **Note**: Exported only for testing purposes.
 */
export const __configCache = new Map<string, IBreakpointConfig>();

/**
 * Gets the wrapper `className` from the given breakpoint sizes.
 * @param matches - The breakpoint sizes to get the wrapper `className` from.
 * @returns The wrapper `className`.
 */
function getWrapperClassNameFromMatches(
  matches: Iterable<BreakpointSize>
): string {
  const wrapperStyles = [S.hidden];
  for (const size of matches) {
    wrapperStyles.push(breakpointSizeToClassMap[size]);
  }
  return wrapperStyles.join(' ');
}

/**
 * Gets the breakpoint configuration for the given breakpoint sizes.
 * @param matches - The breakpoint sizes to get the configuration for.
 * @returns The breakpoint configuration.
 */
export function getConfigFromMatches(
  matches: Set<BreakpointSize>
): IBreakpointConfig {
  const cacheKey = breakpointSizes
    .filter((size) => matches.has(size))
    .join('.');

  if (__configCache.has(cacheKey)) return __configCache.get(cacheKey)!;

  const config: IBreakpointConfig = {
    id: __configCache.size,
    matches,
    className: getWrapperClassNameFromMatches(matches)
  };

  __configCache.set(cacheKey, config);
  return config;
}

/**
 * Determines if a given string is a valid breakpoint device.
 * @param key - The string to check.
 * @returns `true` if the string is a valid breakpoint device, `false` otherwise.
 */
function isBreakpointDevice(key: string): key is BreakpointDevice {
  return key in breakpointDeviceToSize;
}

/**
 * Determines if a given string is a valid breakpoint size range.
 * @param key - The string to check.
 * @returns `true` if the string is a valid breakpoint size range, `false` otherwise.
 */
function isBreakpointSizeRange(key: string): key is BreakpointSizeRange {
  return breakpointSizeRanges.has(key as BreakpointSizeRange);
}

const breakpointSizesSet = new Set(breakpointSizes);

/**
 * Determines if a given string is a valid breakpoint size.
 * @param key - The string to check.
 * @returns `true` if the string is a valid breakpoint size, `false` otherwise.
 */
function isBreakpointSize(key: string): key is BreakpointSize {
  return breakpointSizesSet.has(key as BreakpointSize);
}

/**
 * Gets all the breakpoint sizes from a given breakpoint size range.
 * @param range - The breakpoint size range.
 * @returns An array of breakpoint sizes.
 * @throws A {@link BreakpointGroupError} in non-production environments if the range is invalid.
 */
function getBreakpointSizesFromRange(
  range: BreakpointSizeRange
): Array<BreakpointSize> {
  const [start, end] = range.split(':') as [BreakpointSize, BreakpointSize];
  const startIndex = breakpointSizeToIndexMap.get(start)!;
  const endIndex = breakpointSizeToIndexMap.get(end)!;

  // sanity check...
  if (isNullish(startIndex) || isNullish(endIndex) || startIndex >= endIndex) {
    const msg = `"${range}" is not a valid breakpoint size range.`;

    // in production, log an error, but return an empty array. This will cause
    // the corresponding breakpoint configuration to ignore this range.
    if ((process.env.NEXT_PUBLIC_APP_ENV === "prod")) {
      LoggerService.error(msg);
      return [];
    }

    // in non-production, throw an error for developer visibility.
    throw new BreakpointGroupError(msg);
  }

  return breakpointSizes.slice(startIndex, endIndex + 1);
}

/**
 * Attempts to add the given breakpoint sizes to the group coverage set.
 * @param coverage - The coverage set to add to.
 * @param sizes - An array of breakpoint sizes to add.
 * @throws A {@link BreakpointGroupError} if a duplicate breakpoint size is found.
 */
export function addBreakpointToGroupCoverage(
  coverage: Set<BreakpointSize>,
  sizes: Iterable<BreakpointSize>
): void {
  for (const size of sizes) {
    // If a breakpoint size has duplicate coverage...
    if (coverage.has(size)) {
      const msg = `"${size}" is already matched by another breakpoint configuration.`;

      // in production, log an error, but continue. This will cause multiple breakpoints
      // to match the same size, but it won't crash the app.
      if ((process.env.NEXT_PUBLIC_APP_ENV === "prod")) {
        LoggerService.error(msg);
        continue;
      }

      // in non-production, throw an error for developer visibility.
      throw new BreakpointGroupError(msg);
    }
    coverage.add(size);
  }
}

/**
 * Adds the given breakpoint sizes to the given breakpoint configuration match set.
 * @param matchSet - The set to add to.
 * @param sizes - An array of breakpoint sizes to add.
 */
function addBreakpointsToMatchSet(
  matchSet: Set<BreakpointSize>,
  sizes: Iterable<BreakpointSize>
): void {
  for (const size of sizes) {
    // In non-production, log a warning if a duplicate breakpoint size is found.
    // Though ignore otherwise since it's won't cause any issues.
    if (!(process.env.NEXT_PUBLIC_APP_ENV === "prod") && matchSet.has(size)) {
      LoggerService.warn(
        `A breakpoint configuration contains a duplicate size: ${size}`
      );
      continue;
    }
    matchSet.add(size);
  }
}

/**
 * Gets all the breakpoint sizes from a given list of breakpoint media keys.
 * @param medias - A list of breakpoint media keys.
 * @returns A set of breakpoint sizes.
 * @throws A {@link BreakpointGroupError} if no breakpoint media keys are provided.
 * @throws A {@link BreakpointGroupError} if a breakpoint media key is unsupported.
 */
export function getMatchesFromMedia(
  ...medias: NonEmptyArray<BreakpointMediaKey>
): Set<BreakpointSize> {
  // If no media keys are provided...
  if (medias.length === 0) {
    const msg =
      'A breakpoint configuration must supply at least one media key.';

    // in production, log an error, but return an empty set. This will cause
    // the corresponding breakpoint to never render, but it won't crash the app.
    if ((process.env.NEXT_PUBLIC_APP_ENV === "prod")) {
      LoggerService.error(msg);
      return new Set();
    }

    // in non-production, throw an error for developer visibility.
    throw new BreakpointGroupError(msg);
  }

  const matches = new Set<BreakpointSize>();
  for (const media of medias) {
    let sizes: Array<BreakpointSize>;
    if (isBreakpointDevice(media)) {
      sizes = breakpointDeviceToSize[media];
    } else if (isBreakpointSizeRange(media)) {
      sizes = getBreakpointSizesFromRange(media);
    } else if ((process.env.NEXT_PUBLIC_APP_ENV === "prod") || isBreakpointSize(media)) {
      // optimization: in prod, assume `media` is a breakpoint size at this point.
      // If it's somehow not, it will just be ignored anyways.
      sizes = [media];
    } else {
      exhaustiveGuard(
        media,
        new BreakpointGroupError(
          `${media} is not a supported breakpoint media key.`
        )
      );
    }

    addBreakpointsToMatchSet(matches, sizes);
  }

  return matches;
}

/** Represents a {@link Breakpoint} React element. This is what `<Breakpoint />` returns. */
export type BreakpointElement = ReactElement<
  ComponentProps<typeof Breakpoint>,
  typeof Breakpoint
>;

/**
 * Gets the breakpoint configurations for a given list of {@link Breakpoint} elements.
 * @param elements - An array of {@link BreakpointElement} instances of a `BreakpointGroup`.
 * @returns An array of {@link BreakpointConfigWithNode} instances.
 * @throws A {@link BreakpointGroupError} if there is missing coverage.
 * @throws A {@link BreakpointGroupError} if there are multiple default breakpoints.
 */
export function getBreakpointConfigs(
  elements: Array<BreakpointElement>
): Array<BreakpointConfigWithNode> {
  const coverage = new Set<BreakpointSize>();
  const result: Array<BreakpointConfigWithNode> = [];

  let defaultElement: BreakpointElement | undefined;
  for (const element of elements) {
    // handle "default" after determining coverage
    if (element.props.default) {
      // If we have already seen a default breakpoint...
      if (defaultElement) {
        const msg = 'Multiple default breakpoints found.';

        // in production, log an error, but continue. This will cause only the first
        // default breakpoint to be used, but it won't crash the app.
        if ((process.env.NEXT_PUBLIC_APP_ENV === "prod")) {
          LoggerService.error(msg + ' Only the first will be used.');
          continue;
        }

        // in non-production, throw an error for developer visibility.
        throw new BreakpointGroupError(msg);
      }

      defaultElement = element;
      continue;
    }

    const { media } = element.props;
    const matches = Array.isArray(media)
      ? getMatchesFromMedia(...media)
      : getMatchesFromMedia(media);

    const config = getConfigFromMatches(matches);

    addBreakpointToGroupCoverage(coverage, matches);
    result.push([config, element.props.children]);
  }

  // check if a "default" is necessary
  const [notCovered] = difference(breakpointSizes, coverage);
  if (notCovered.length > 0) {
    // If a default breakpoint is missing...
    if (defaultElement === undefined) {
      const msg =
        `Missing breakpoint configuration for sizes: ${notCovered.toString()}.` +
        ' Add configurations for these sizes, or add a default breakpoint.';

      // in production, log an error, but return the result as is. This will cause
      // uncovered breakpoints to render nothing, but it won't crash the app.
      if ((process.env.NEXT_PUBLIC_APP_ENV === "prod")) {
        LoggerService.error(msg);
        return result;
      }

      // in non-production, throw an error for developer visibility.
      throw new BreakpointGroupError(msg);
    }

    const matches = new Set(notCovered);
    const config = getConfigFromMatches(matches);
    result.push([config, defaultElement.props.children]);
  } else if (!(process.env.NEXT_PUBLIC_APP_ENV === "prod") && defaultElement !== undefined) {
    // Log a warning if a default breakpoint is present, but not needed.
    LoggerService.warn(
      'Encountered a redundant default breakpoint; all breakpoint sizes are already covered.'
    );
  }

  return result;
}
