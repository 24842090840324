import { createContext } from 'react';
import type { BreakpointSize } from '.';

/**
 * A context that provides the current breakpoint size of the screen.
 * `null` is only used during the server-render phase, when the screen size is unknown,
 * and it should render all breakpoints.
 */
export const BreakpointSizeContext = createContext<BreakpointSize | null>(null);
BreakpointSizeContext.displayName = 'BreakpointSizeContext';
