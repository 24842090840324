'use client';

import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import {
  AppBuildType,
  EnvironmentService
} from '@/services/isomorphic/EnvironmentService';
import LoggerService from '@/services/isomorphic/LoggerService';
import { FC, useCallback, useContext, type ErrorInfo } from 'react';
import { Alert } from '../../core-ui/Alert';
import { IconTypes } from '../../core-ui/Icon';
import { useIsHydrating } from '../../utils/HydrationProvider';
import { RenderError } from '../devtools/RenderError';
import { TopErrorManagerContext } from '../devtools/TopErrorManagerContext';
import { parseComponentsFromErrorInfo } from '../utils/parseComponentFromErrorInfo';
import {
  BasicErrorBoundary,
  type ErrorBoundaryProps
} from './BasicErrorBoundary';
import { general_errors_defaultFallback } from "@/lang/__generated__/ahnu/general_errors_defaultFallback";

/** * Props for the {@link CommonErrorBoundary} component. */
export type CommonErrorBoundaryProps = ErrorBoundaryProps & {
  /**
   * Either a {@link ReactNode} or a function that returns a `ReactNode`
   * to be displayed when an error occurs.
   *
   * **NOTE:** If no value (`undefined`) is provided, the error boundary
   * will render a default alert as the fallback. To render nothing
   * for the fallback, provide `null`.
   */
  fallback?: ErrorBoundaryProps['fallback'];

  /**
   * Whether to suppress the error alert.
   * If a function is provided, it will be called with the error thrown.
   *
   * **NOTE:** Use this cautiously as render errors are often unexpected and
   * should be visible to developers and QA.
   * @default false
   */
  suppressRenderErrorAlert?: boolean | ((error: unknown) => boolean);
};

/**
 * An error boundary component which builds upon the {@link BasicErrorBoundary}
 * functionality to provide better defaults and error reporting.
 * @param props - The props to pass to the error boundary.
 */
export const CommonErrorBoundary: FC<CommonErrorBoundaryProps> = ({
  children,
  fallback,
  fallbackComponent,
  onError,
  onReset,
  suppressRenderErrorAlert = false
}) => {
  const [msg] = useLocaleMessage();
  const { addError } = useContext(TopErrorManagerContext);
  const isHydrating = useIsHydrating();

  const handleError = useCallback(
    (error: unknown, info: ErrorInfo) => {
      onError?.(error, info);

      const renderError = new RenderError({
        error,
        inHydration: isHydrating,
        componentStack:
          // Only parse the component stack in development mode
          // since in production, the component stack is minified.
          (process.env.IS_DEVELOPMENT_MODE ? "dev" : "prod") === AppBuildType.DevelopmentMode
            ? parseComponentsFromErrorInfo(info)
            : undefined
      });

      LoggerService.error(renderError);

      const shouldAddError =
        typeof suppressRenderErrorAlert === 'boolean'
          ? !suppressRenderErrorAlert
          : !suppressRenderErrorAlert(error);

      if (shouldAddError) addError(renderError);
    },
    [addError, onError, isHydrating, suppressRenderErrorAlert]
  );

  if (fallbackComponent) {
    return (
      <BasicErrorBoundary
        onError={handleError}
        onReset={onReset}
        fallbackComponent={fallbackComponent}
      >
        {children}
      </BasicErrorBoundary>
    );
  }

  return (
    <BasicErrorBoundary
      onError={handleError}
      onReset={onReset}
      fallback={
        fallback === undefined ? (
          <Alert icon={IconTypes.Alert} iconAlignment="center">
            {msg(general_errors_defaultFallback)}
          </Alert>
        ) : (
          fallback
        )
      }
    >
      {children}
    </BasicErrorBoundary>
  );
};
