import { useEffect, type FC } from 'react';
import { PreviewService } from '@/services/isomorphic/PreviewService';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { useLocalStorage } from '@/react/hooks/useLocalStorage';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { isValidDate, coerceDateToZone } from '@/utils/date-utils';
import { Button } from '../../core-ui/Button';
import { Icon, IconTypes } from '../../core-ui/Icon';

import S from './styles.module.scss';

/**
 * A component that allows setting a preview date.
 */
const PreviewMenu: FC = observer(function PreviewMenu() {
  const [previewDate, setPreviewDate] = useLocalStorage<number | null>(
    'DECKERS_PREVIEW_DATE',
    null
  );

  useEffect(() => {
    if (previewDate !== null) {
      // convert to PT
      const date = new Date(previewDate);
      const ptDate = coerceDateToZone(date, 'America/Los_Angeles');
      PreviewService.setPreviewDate(ptDate.toMillis());
    }
  }, [previewDate]);

  return (
    <>
      <label htmlFor="preview-date" className={S.previewLabel}>
        Preview Date (PT)
      </label>
      <input
        className={S.previewInput}
        id="preview-date"
        type="datetime-local"
        name="preview-date"
        onChange={(e) => {
          // This should never be anything but a date.

          const date = new Date(e.target.value);
          if (isValidDate(date)) {
            setPreviewDate(date.getTime());
          }
        }}
        value={
          // Convert to ISO string, than slice to remove seconds and timezone in order to conform
          // to the expected format for a datetime-local input (YYYY-MM-DDTHH:mm).
          previewDate !== null
            ? DateTime.fromJSDate(new Date(previewDate)).toISO().slice(0, 16)
            : ''
        }
      />

      <div className={S.buttonContainer}>
        <Button
          className={S.button}
          size="sm"
          variant="secondary"
          disabled={previewDate === null}
          onClick={() => {
            PreviewService.setPreviewDate(Date.now());
            PreviewService.startPreview();
          }}
        >
          Stop
        </Button>
        <Button
          className={S.button}
          size="sm"
          variant="primary"
          disabled={previewDate === null}
          onClick={() => {
            if ((typeof window !== "undefined"))
              navigator.clipboard.writeText(PreviewService.getPreviewUrl());
            PreviewService.startPreview();
          }}
        >
          Start
        </Button>
      </div>
      <Button
        className={S.button}
        size="sm"
        variant="text"
        disabled={previewDate === null}
        onClick={() => {
          if ((typeof window !== "undefined"))
            navigator.clipboard.writeText(PreviewService.getPreviewUrl());
        }}
      >
        Copy Preview Link
      </Button>
    </>
  );
});

/**
 * A component that allows setting a preview date. Is not displayed inproduction.
 */
const InternalPreview: FC = () => {
  const [show, setShow] = useLocalStorage<boolean>(
    'DECKERS_INTERNAL_SHOW_PREVIEW_MENU',
    false
  );

  return (
    <div className={S.previewContainer}>
      <Button
        className={S.previewButton}
        variant="text"
        onClick={() => setShow(true)}
      >
        <Icon icon={IconTypes.Eye} />
      </Button>
      <div className={classes(S.previewCard, show && S.visible)}>
        <div className={S.titleContainer}>
          <div className={S.title}>Preview</div>
          <Button
            variant="text"
            className={S.close}
            onClick={() => setShow(false)}
          >
            <Icon icon={IconTypes.CloseLight} />
          </Button>
        </div>
        <div className={S.menuContainer}>
          <PreviewMenu />
        </div>
      </div>
    </div>
  );
};

/**
 * A component that allows setting a preview date. Is not displayed inproduction.
 * @returns The preview component.
 */
export const Preview: FC = (process.env.NEXT_PUBLIC_APP_ENV === "prod")
  ? () => null
  : InternalPreview;
