import type { NonEmptyArray } from '@/type-utils';
import { getBreakpointSize, BreakpointMediaKey } from './internal';
import { getMatchesFromMedia } from './internal/getBreakpointConfigs';
import type { BreakpointGroup as Breakpoints } from './BreakpointGroup';

/**
 * A helper for determining if the given window's width matches one of the provided media keys.
 *
 * **Note**: this function is *not* reactive, i.e. it should not be called during render. Use
 * the {@link Breakpoints} component to vary UI based on the screen size. Use this function within
 * event handlers or other imperative code.
 *
 * @param w - The window to check.
 * @param mediaKeys - An array of media keys to match against.
 * @returns `true` if the window's width matches one of the provided media keys, `false` otherwise.
 * @example
 * if (mediaMatches(window, 'phone', 'tablet')) {
 *   // Do something for phone or tablet
 * }
 */
export function mediaMatches(
  w: Window,
  ...mediaKeys: NonEmptyArray<BreakpointMediaKey>
): boolean {
  const currentMatch = getBreakpointSize(w.innerWidth);
  const matches = getMatchesFromMedia(...mediaKeys);
  return matches.has(currentMatch);
}
