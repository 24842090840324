import LoggerService from '@/services/isomorphic/LoggerService/';
import { JSONValue } from '@/type-utils';
import { useCallback, useSyncExternalStore } from 'react';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';

/**
 * A hook that provides a value stored in {@link localStorage}.
 * @param key - The key to use for the value.
 * @param [defaultValue] - The default value to use if the key is not found.
 * @returns A tuple containing the value and a function to set the value.
 */
export const useLocalStorage = <T extends JSONValue<true>>(
  key: string,
  defaultValue: T
): [T | null, (value: T) => void] => {
  /**
   * Subscribes to the `storage` event and calls the listener when the event is fired.
   * @param listener - The listener to call when the event is fired.
   * @returns A function to unsubscribe the listener.
   */
  const subscribe = useCallback(
    function subscribeToLocalStorage(listener: () => void) {
      if ((typeof window === "undefined")) return () => {};

      const handler = (e: StorageEvent): void => {
        if (e.storageArea === window.localStorage && e.key === key) {
          listener();
        }
      };

      window.addEventListener('storage', handler);
      return () => {
        window.removeEventListener('storage', handler);
      };
    },
    [key]
  );

  const value = useSyncExternalStore(
    subscribe,
    () => {
      const value = window.localStorage.getItem(key);
      if (!value) return defaultValue;

      let result: JSONValue<true>;
      try {
        result = JSON.parse(value);
      } catch {
        result = defaultValue;
      }

      return result as T;
    },
    () => defaultValue
  );

  const setValue = useCallback(
    (newValue: JSONValue<true>) => {
      if (typeof window === 'undefined') return;

      let serializedValue: string;
      try {
        serializedValue = JSON.stringify(newValue);
      } catch (error) {
        LoggerService.error(
          new Error('Failed to serialize value for local storage.', {
            cause: error
          })
        );
        return;
      }

      window.localStorage.setItem(key, serializedValue);
      // Storage events are not fired automatically when a value is set.
      window.dispatchEvent(
        new StorageEvent('storage', { storageArea: localStorage, key })
      );
    },
    [key]
  );

  return [value, setValue];
};
