import {
  breakpointLg,
  breakpointMd,
  breakpointSm,
  breakpointXl,
  breakpointXs,
  breakpointXxs
} from '@/styles/variables.module.scss';

/**
 * Extracts the number of pixels from an SCSS dimension with `px` unit.
 *
 * @param value - An SCSS dimension with `px` unit.
 * @returns The number of pixels.
 * @throws When the breakpoint cannot be parsed.
 * @example
 * parseSCSSPixels('1024px') // 1024
 */
export function parseSCSSPixels(value: string): number {
  // '0' is a special case, as it is commonly written without the unit ('0' instead of '0px')
  if (value === '0') return 0;

  if (!value.endsWith('px')) {
    throw new Error(
      `Cannot parse breakpoint "${value}", since it doesn't end with "px"`
    );
  }

  const px = parseInt(value.slice(0, -2), 10);
  if (!Number.isFinite(px) || Number.isNaN(px)) {
    throw new Error(
      `Cannot parse breakpoint "${value}", since it's not a valid number. Tried "${px}"`
    );
  }

  return px;
}

export const breakpointSizes = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'] as const;

export const breakpointSizeToIndexMap = new Map(
  breakpointSizes.map((size, index) => [size, index])
);

/**
 * Represents a small range of screen widths.
 * The value typically refers to some configured value, up to the next breakpoint.
 * @example
 * 'md' // represents screens from 768px to 1023px wide.
 */
export type BreakpointSize = (typeof breakpointSizes)[number];

export const breakpointValues = {
  xxs: parseSCSSPixels(breakpointXxs),
  xs: parseSCSSPixels(breakpointXs),
  sm: parseSCSSPixels(breakpointSm),
  md: parseSCSSPixels(breakpointMd),
  lg: parseSCSSPixels(breakpointLg),
  xl: parseSCSSPixels(breakpointXl)
} as const satisfies Record<BreakpointSize, number>;

export const breakpointSizeRanges = new Set([
  /* eslint-disable prettier/prettier -- This is more readable. */
  "xxs:xs", "xxs:sm", "xxs:md", "xxs:lg", // "xxs:xl" is excluded because it spans the entire range
  "xs:sm" , "xs:md" , "xs:lg" , "xs:xl",
  "sm:md" , "sm:lg" , "sm:xl",
  "md:lg" , "md:xl",
  "lg:xl"
  /* eslint-enable prettier/prettier */
] as const satisfies Array<`${BreakpointSize}:${BreakpointSize}`>);

/**
 * Represents a range of breakpoint sizes, inclusive.
 * @example
 * 'xxs:sm' // represents breakpoint sizes "xxs", "xs", & "sm" (from 0px to 767px wide).
 */
export type BreakpointSizeRange =
  typeof breakpointSizeRanges extends Set<infer T> ? T : never;

export const breakpointDeviceToSize = {
  phone: ['xxs', 'xs'],
  tablet: ['sm', 'md'],
  desktop: ['lg', 'xl']
} as const satisfies Record<string, Array<BreakpointSize>>;

/**
 * Represents a range of breakpoint sizes based on device type.
 * @example
 * 'tablet' // represents breakpoint sizes "sm" & "md" (640px to 1023px)
 * */
export type BreakpointDevice = keyof typeof breakpointDeviceToSize;

/** Represents a key that can be used to configure a breakpoint to match. */
export type BreakpointMediaKey =
  | BreakpointSize
  | BreakpointSizeRange
  | BreakpointDevice;

/**
 * Gets the matching breakpoint size for a given width.
 * @param width - The width of the screen.
 * @returns The matching breakpoint size.
 */
export function getBreakpointSize(width: number): BreakpointSize {
  if (width >= breakpointValues.xl) return 'xl';
  if (width >= breakpointValues.lg) return 'lg';
  if (width >= breakpointValues.md) return 'md';
  if (width >= breakpointValues.sm) return 'sm';
  if (width >= breakpointValues.xs) return 'xs';
  return 'xxs';
}
